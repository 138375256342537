export default{
    "header.home"       : "Accueil",
    "header.account"    : "Mon Compte",
    "header.settings"   : "Paramètres",
    "header.myProfile"  : "Mon Profil",
    "header.signout"    : "Déconnexion",
    "header.spordle.id" : "Aller à Spordle ID",

    "header.play"    : "Inscription pour jouer",
    "header.clinics" : "Inscription aux stages",
    "header.about"   : "À propos",
    "header.support" : "Support",
    "header.login"   : "Connexion",
}